<messages>["../Dashboard"]</messages>

<template>
  <data-card :title="$t ('regStats.title')">
    <v-progress-circular
      v-if="isLoading"
      indeterminate/>
    <name-value-block
      v-else
      :items="items"/>
  </data-card>
</template>

  <script>
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import {mapActions} from 'vuex'
  import DataCard from '@/app/core/components/DataCard'

  export default {
    components: {
      DataCard,
      NameValueBlock
    },

    data () {
      return {
        isLoading: false,
        regData: null
      }
    },

    computed: {
      items () {
        const f = (n, v) => ({
          name: this.$t (`regStats.${n}`),
          value: v
        })

        return [
          f ('registryCount', this.regCount),
          f ('tldCount', this.tldCount)
        ]
      },

      tldCount () {
        if (!this.regData) { return '-' }

        return Object.keys (this.regData).reduce ((r, key) => {
          return [...r, ...this.regData[key].tlds]
        }, []).length
      },
      regCount () {
        if (!this.regData) { return '-' }

        return Object.keys (this.regData).length
      }
    },

    created () {
      this.loadRegistries ()
    },

    methods: {
      ...mapActions ({
        getRegistryTypeData: 'cache/getRegistryTypeData'
      }),

      async loadRegistries () {
        this.isLoading = true
        this.regData = await this.getRegistryTypeData ()
        this.isLoading = false
      }
    }
  }
  </script>
