<messages>["./RegistryList"]</messages>

<template>
  <v-card>
    <v-card-title primary title>
      <div
        v-t="'title'"
        class="text-h5"/>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        hide-default-footer
        :items="registries"
        :items-per-page="-1"
        sort-by="label"
        :no-data-text="$t(`general.pagination.${isLoading ? 'loading' : 'noDataText'}`)"
        :loading="isLoading"
        class="tld-table elevation-1">
        <template #headerCell="props">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span
                class="primary--text"
                v-on="on"
                v-text="props.header.text"/>
            </template>
            <span v-text="props.header.text"/>
          </v-tooltip>
        </template>
        <template #item="props">
          <tr>
            <td>
              <router-link
                :to="{name: 'registry.view', params: {id: props.item.id}}"
                v-text="props.item.label"/>
            </td>
            <td v-text="props.item.id"/>
            <td>
              <template v-for="item in props.item.tlds">
                <span
                  :key="item"
                  class="tldItem"
                  v-text="item"/>{{ '\n' }}
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    name: 'RegistryList',

    data () {
      return {
        registries: [],
        isLoading: false
      }
    },

    computed: {
      headers () {
        return [
          {
            text: this.$t ('header.registry'),
            value: 'label'
          },
          {
            text: this.$t ('header.id'),
            value: 'id'
          },
          {
            text: this.$t ('header.tlds'),
            value: 'tlds'
          }
        ]
      }
    },

    created () {
      this.loadRegistries ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData',
        getRegistryTypeData: 'cache/getRegistryTypeData'
      }),

      /**
       * load available registry data from the backend and populate them as
       * {@code this.registryItems} (alphabetically sorted)
       */
      async loadRegistries () {
        this.isLoading = true
        const data = await this.getRegistryTypeData ()

        for (const prop in data) {
          if (data.hasOwnProperty (prop)) {
            this.registries.push (data[prop])
          }
        }

        this.isLoading = false
        this.$emit ('loaded')
      }
    }
  }
</script>

<style lang="scss">
.tld-table table {
  table-layout: fixed;

  th {
    // Label
    &:nth-child(1) {
      width: 25%;
    }

    // ID
    &:nth-child(2) {
      width: 15%;
    }

    // TLD
    // &:nth-child(3) {
    // }
  }
}

.tldItem:not(:last-child) {
  margin-right: .3em;
}
</style>
