<messages>["./NameServerTable"]</messages>

<template>
  <v-card>
    <v-card-title primary title>
      <div
        v-t="'label.title'"
        class="text-h5"/>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="translatedNS"
        sort-by="location"
        :items-per-page="-1"
        hide-default-footer
        class="tld-table elevation-1"
        :loading="isLoading"
        :no-data-text="$t(`general.pagination.${isLoading ? 'loading' : 'noDataText'}`)">
        <template #item="{item}">
          <tr>
            <td>
              {{ item.location }}
            </td>
            <td>
              {{ item.name }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    data () {
      return {
        ns: [],
        isLoading: false
      }
    },

    computed: {
      headers () {
        return [
          {
            text: this.$t ('header.location'),
            value: 'location'
          },
          {
            text: this.$t ('header.name'),
            value: 'name'
          }
        ]
      },

      translatedNS () {
        if (!this.ns) return []
        const ns = this.ns.map ((o) => ({
          name: o.name,
          location: this.getDescription (o.descriptions)
        }))

        return ns
      }
    },

    created () {
      this.loadNameServers ()
    },

    methods: {
      ...mapActions ({
        getNameServers: 'cache/getNameServers'
      }),

      async loadNameServers () {
        this.isLoading = true
        this.ns = await this.getNameServers ()
        this.isLoading = false
        this.$emit ('loaded')
      },

      getDescription (d) {
        const desc = d[this.$i18n.locale]

        return desc ? `${desc}` : ''
      }
    }
  }
</script>
