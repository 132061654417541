<messages>["./LoginInfo"]</messages>

<template>
  <v-card>
    <v-card-title primary title>
      <div>
        <div
          v-t="'label.title'"
          class="text-h5"/>
        <div
          v-if="isImpersonated && userDisplayName.effectiveUserDisplayName"
          v-t="`(${$t('user.title')}: ${userDisplayName.effectiveUserDisplayName})`"
          class="cgwng-subheading"/>
      </div>
    </v-card-title>
    <v-card-text>
      <template v-if="isLoading">
        <v-progress-linear indeterminate/>
      </template>
      <template v-else>
        <v-tabs
          v-model="tab"
          grow>
          <v-tab>
            {{ $t('label.activity') }}
          </v-tab>
          <v-tab>
            {{ $t('label.history') }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row justify="space-between" class="ma-3">
              <span class="font-weight-bold">
                {{ $t("label.lastSuccessfulLogin") }}:
              </span>
              {{ info.lastSuccessfulLogin ? formatDate (info.lastSuccessfulLogin.loginDate) : "-" }}
            </v-row>
            <v-row justify="space-between" class="ma-3">
              <span class="font-weight-bold">
                {{ $t("label.lastFailedLogin") }}:
              </span>
              {{ info.lastFailedLogin ? formatDate (info.lastFailedLogin.loginDate) : "-" }}
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              :headers="headers"
              :items="info.logins"
              hide-default-footer
              :items-per-page="-1">
              <template #item="props">
                <tr>
                  <td>{{ formatDate (props.item.loginDate) }}</td>
                  <td>
                    <v-icon :color="props.item.success ? 'success' : 'error'">
                      {{ props.item.success ? 'check_circle' : 'cancel' }}
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    data () {
      return {
        isLoading: false,
        info: undefined,
        tab: 0
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'userDisplayName',
        'isImpersonated'
      ]),

      headers () {
        return [
          {
            text: this.$t ('label.date'),
            sortable: false,
            value: 'loginDate'
          },
          {
            text: this.$t ('label.success'),
            sortable: false,
            value: 'success'
          }
        ]
      }
    },

    watch: {
      isImpersonated () {
        this.loadInfo ()
      }

    },

    created () {
      this.loadInfo ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadInfo () {
        this.isLoading = true
        this.fetchData ({
          op: 'user/loginInfo',
          cb: data => {
            this.info = data.info
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
