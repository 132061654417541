<messages>["./Dashboard"]</messages>

<template>
  <base-layout mw2>
    <unsupported-browser-alert future/>
    <v-col cols="12" sm="6" md="8">
      <v-card>
        <v-card-title primary-title>
          <div
            v-t="'welcome.title'"
            class="text-h5"/>
        </v-card-title>
        <v-card-text>
          <p v-t="'welcome.functionality.text'"/>
          <ul class="ml-4">
            <li
              v-for="item in features"
              :key="item"
              v-t="`welcome.functionality.${item}`"/>
          </ul>
          <br>
          <p>
            <i18n :path="'welcome.payload.text'">
              <template #docLink>
                <router-link
                  v-t="'welcome.payload.doc'"
                  :to="{ name: 'payload-doc'}"/>
              </template>
            </i18n>
          </p>
          <p>
            <i18n :path="'welcome.feedback.text'">
              <template #emailLink>
                <a
                  href="mailto:partners@corehub.net"
                  v-text="'partners@corehub.net'"/>
              </template>
            </i18n>
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <login-info-card/>
    </v-col>
    <v-col id="tlds" cols="12">
      <registry-list @loaded="scrollToLocation"/>
    </v-col>
    <v-col id="name-servers" cols="12">
      <name-server-table @loaded="scrollToLocation"/>
    </v-col>
    <v-col v-if="mayManageAllEntities" md="6">
      <data-card :title="$t ('sysinfo.title')">
        <v-progress-circular
          v-if="isLoading"
          indeterminate/>
        <name-value-block
          v-else
          :items="[
            {name: $t('sysinfo.flavourLabel'), value: $t(`sysinfo.flavour.${flavour}`)},
            {name: $t('sysinfo.version.backend'), value: backendVersion},
            {name: $t('sysinfo.version.frontend'), value: versionString}
          ]"/>
      </data-card>
    </v-col>
    <v-col cols="12" sm="4">
      <registry-statistics/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions, mapGetters} from 'vuex'

  import UnsupportedBrowserAlert from '@/app/core/components/UnsupportedBrowserAlert'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import DataCard from '@/app/core/components/DataCard'

  import RegistryList from './components/RegistryList'
  import RegistryStatistics from './components/RegistryStatistics'
  import NameServerTable from './components/NameServerTable'
  import LoginInfoCard from './components/LoginInfoCard'

  const VUE_APP_FRONTEND_VERSION = process.env.VUE_APP_FRONTEND_VERSION
  const VUE_APP_FRONTEND_BRANCH = process.env.VUE_APP_FRONTEND_BRANCH
  const VUE_APP_FRONTEND_REVISION = process.env.VUE_APP_FRONTEND_REVISION

  export default {
    name: 'Dashboard',

    components: {
      DataCard,
      RegistryList,
      BaseLayout,
      RegistryStatistics,
      NameValueBlock,
      UnsupportedBrowserAlert,
      NameServerTable,
      LoginInfoCard
    },

    data () {
      return {
        backendVersion: '',
        flavour: 'devel',
        version: VUE_APP_FRONTEND_BRANCH || VUE_APP_FRONTEND_VERSION,
        revision: VUE_APP_FRONTEND_REVISION,
        isLoading: false
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'mayManageAllEntities'
      ]),
      features () {
        return [
          'domainManagement',
          'domainCheck',
          'applicationManagement',
          'contactManagement',
          'hostManagement',
          'zoneManagement',
          'dnssec',
          'zoneLinkage',
          'gnrEMail',
          'clientManagement',
          'machineClients',
          'communications',
          'user',
          'userProfile',
          'payload',
          'tariffs',
          'reports',
          'wdrpReport',
          'wdrpAuditTrail',
          'domainStatistics',
          'invoiceManagement',
          'emailVerification',
          'templates'
        ]
      },

      versionString () {
        return this.$t (
          'sysinfo.version.format',
          {
            version: this.version,
            revision: this.revision
          })
      }
    },
    watch: {
      '$route.query.location' () {
        this.scrollToLocation ()
      }
    },

    created () {
      this.loadMetaData ()
    },

    mounted () {
      this.scrollToLocation ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      scrollToLocation () {
        this.$nextTick (
          () => {
            if (this.$route.query.location) {
              try {
                this.$vuetify.goTo ('#' + this.$route.query.location, {duration: 0})
              } catch (ex) {}
            }
          }
        )
      },

      loadMetaData () {
        this.isLoading = true

        this.fetchData ({
          op: 'getMetaData',
          cb: data => {
            this.backendVersion = data.backendVersion
            this.flavour = data.flavour
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
